<template>
  <base-section id="about-our-product">
    <base-section-heading
      color="grey lighten-2"
      title="About Us"
    />

    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div><span style="font-weight:bold;">Stout Funding</span> is the brainchild of Chris Herford, longtime veteran of the lending industry, and Rockford Steele, Real Estate Investor turned money lender. They saw that investors had a need for lending and didn’t necessarily want to have to become a lending expert to determine which Lender would work best for them. Enter Stout Funding. We’re a Loan Broker that is client centered. Our goal is to be a part of the borrower’s team as their go to lending source. Between all of the lenders that we work with, we have countless loan products available and are able to find a solution for just about any loan scenario.</div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      cards: [
        {
          title: 'Our Company',
          subtitle: '',
          text: 'Our company has provided independent claims adjusting services to the insurance industry since 1993.  We currently have over 150 individually owned and operated branch offices throughout the United States.  Our branch offices have an average of more than 25 years of Property and Casualty adjusting experience.  Those services include property, general liability, auto, trucking, cargo, product liability, premises liability and many other lines of insurance adjusting.',
          callout: '01',
        },
        {
          title: 'Our Mission',
          subtitle: '',
          text: 'Our mission is to be the premier national independent adjusting firm by serving our customers, our colleagues and the communities where we live.',
          callout: '02',
        },
        {
          title: 'Our Values',
          subtitle: '',
          text: 'Corporately and individually we will: Approach every single day with professionalism, integrity, honesty, excellence, respect, and passion with a view towards results for our customers.',
          callout: '03',
        },
      ],
    }),
  }
</script>
